
#preloader {
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color: $primary;
	z-index: 20000; 
	
	img {
		width: 480px;
		max-width: 94%;
	}
}