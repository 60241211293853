// Servizi
//----------------------------


/* mappa */
.map-frame {
	position: relative;
	height: calc(100vh - 130px);

	iframe {
		position: absolute;
		top: 0;
		left: 20px;
		width: calc(100% - 20px);
		height: 100%;

		background-color: #e9e9e8;
	}
}



/* colonna intro e lista */
.service-list {
	width: 24vw;
	min-width: 520px;
	height: calc(100vh - 130px);
	overflow-x: hidden;
	overflow-y: auto;

	.content {
		padding-left: 40px;
	}

	.intro {
		padding-left: 40px;
		margin: 40px auto 0;
	}

	.accordions {
		margin-top: 2rem;
		padding-right: 20px;
		padding-bottom: 60px;
	}
}


// Responsive
//----------------------------

@media only screen and (max-width: 799px) {

	.map-frame {
		display: none;
	}

	.service-list {
		height: auto;
	}
}



@media only screen and (max-width: 550px) {

	.service-list {
		width: 100%;
		min-width: 319px;

		.intro {
			padding-left: 30px;
			padding-right: 30px;
		}

		.content {
			padding-left: 30px;
		}
	}
}
