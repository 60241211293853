// Helpers (Utilities)
//----------------------------


/* WIDHT (pensato per immagini e icone) */
/* --------------------------------------------- */

[class*="item-width-"] {
	max-width: 80%;
}

.item-width-80 {
	width: 80px;
}

.item-width-100 {
	width: 100px;
}

.item-width-120 {
	width: 120px;
}

.item-width-140 {
	width: 140px;
}

.item-width-160 {
	width: 160px;
}

.item-width-180 {
	width: 180px;
}

.item-width-200 {
	width: 200px;
}

.item-width-220 {
	width: 220px;
}

.item-width-240 {
	width: 240px;
}

.item-width-260 {
	width: 260px;
}

.item-width-280 {
	width: 280px;
}

.item-width-300 {
	width: 300px;
}

.item-width-320 {
	width: 320px;
}

.item-width-340 {
	width: 340px;
}

.item-width-360 {
	width: 360px;
}

.item-width-380 {
	width: 380px;
}

.item-width-400 {
	width: 400px;
}

.item-width-420 {
	width: 420px;
}

.item-width-440 {
	width: 440px;
}

.item-width-460 {
	width: 460px;
}

.item-width-480 {
	width: 480px;
}






/* HEIGHT */
/* --------------------------------------------- */
.height-70 {
	height: 70px;
}

.height-100 {
	height: 100px;
}






/* POSITION MODIFIERS (top / bottom) */
/* --------------------------------------------- */
.top10 {
	top: 10px !important;
}

.top20 {
	top: 20px !important;
}

.top30 {
	top: 30px !important;
}

.top40 {
	top: 40px !important;
}

.top50 {
	top: 50px !important;
}

/**/

.bottom10 {
	bottom: 10px !important;
}

.bottom20 {
	bottom: 20px !important;
}

.bottom30 {
	bottom: 30px !important;
}

.bottom40 {
	bottom: 40px !important;
}

.bottom50 {
	bottom: 50px !important;
}

/**/
/**/

.top-10 {
	top: -10px !important;
}

.top-20 {
	top: -20px !important;
}

.top-30 {
	top: -30px !important;
}

.top-40 {
	top: -40px !important;
}

.top-50 {
	top: -50px !important;
}

/**/

.bottom-10 {
	bottom: -10px !important;
}

.bottom-20 {
	bottom: -20px !important;
}

.bottom-30 {
	bottom: -30px !important;
}

.bottom-40 {
	bottom: -40px !important;
}

.bottom-50 {
	bottom: -50px !important;
}









/* Z-INDEX */
/* --------------------------------------------- */
.z-index-1 {
	z-index: 1;
}

.z-index-2 {
	z-index: 2;
}

.z-index-3 {
	z-index: 3;
}

.z-index-4 {
	z-index: 4;
}

.z-index-5 {
	z-index: 5;
}

.z-index-6 {
	z-index: 6;
}

.z-index-7 {
	z-index: 7;
}

.z-index-8 {
	z-index: 8;
}

.z-index-9 {
	z-index: 9;
}

.z-index-10 {
	z-index: 10;
}




// Typography

.is-uppercase.reset-uppercase,
.reset-uppercase {
	text-transform: none !important;
}