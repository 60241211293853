
section {
	
	&.treni {
		text-align: center;

		&:after {
			position: absolute;
			content: "";
			width: 100%;
			height: 44%;
			left: 0;
			bottom: 0;
			z-index: 1;
			background-color: $gray-dark;
		}
		
		.column {
			position: relative;
			text-align: center;
			z-index: 2;
			
			img,
			p {
				width: 80%;
				max-width: 300px;
				margin: 0 auto 30px;
				display: block;
			}
		}

		.col-content {
			min-height: 200px;
		}
		
		.number-round {
			width: 46px !important;
			height: 46px;
			line-height: 45px;
			margin-bottom: 14px;
			font-weight: 700;
			font-size: 20px;
		}
	}
}



// Responsive
//----------------------------


@media only screen and (max-width: 767px) {	

	section {
		
		&.treni {
			padding-bottom: 0; 

			&::after {
				display: none;
			}
		}
	}
}

