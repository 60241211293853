#map {
  height: 100%;
  position: relative;
  width: 100%;
}

.vendor-marker {
  background-size: contain;
  cursor: pointer;
}
