
	.badge {
		margin-top: 30px;
        white-space: nowrap;

		a {
			display: inline-block;
			width: 180px;
			height: 49px;
			margin-right: 10px;
			margin-top: 10px;

			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: cover;
			opacity: 0.88;

			&:hover {
				opacity: 1;
			}

			&.apple {
				background-image: url(/images/common/badge-apple-w.svg);
			}

			&.google {
				background-image: url(/images/common/badge-google-w.svg);
			}

			&.microsoft {
				background-image: url(/images/common/badge-microsoft-w.svg);
			}

            &.huawei {
                background-image: url(/images/common/badge-huawei-w.svg);
            }
        }

        .download-small & {
            margin-bottom: 0;
            margin-top: 30px;

            a {
                margin-right: 4px;
                 margin-left: 4px;
            }
        }

        .download-light & {

            a {
			    &.apple {
				    background-image: url(/images/common/badge-apple-b.svg);
				}

				&.google {
				    background-image: url(/images/common/badge-google-b.svg);
				}

				&.microsoft {
				    background-image: url(/images/common/badge-microsoft-b.svg);
				}

				&.huawei {
				    background-image: url(/images/common/badge-huawei-b.svg);
				}
			}
        }
    }
