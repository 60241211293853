#tpls-slide {
    img {
        width: 80px;
        height: auto;
        @include grayscale_element(100%);
    }

    .slick-slide {
        & > div {
            display: flex !important;
            flex-direction: column;
            & > div {
                display: flex !important;
                justify-content: center;
                align-items: center;
                min-height: 80px;
            }
        }
    }
}
