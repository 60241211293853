// Pagina indice
//----------------------------

.index-item {
	text-align: center;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	
	.image-wrapper,
	.txt {
		text-align: center;
	}
	
	.image-wrapper {
		margin-bottom: 20px;
		
		img {
			width: 100%;
			border-radius: 3px;
		}
	}
	
	.title {
		text-transform: uppercase;
		font-weight: 700;
		text-decoration: none;
	}

	.title,
	p {
		margin-bottom: 1.25rem;
		display: block;
	}

	@include mobile {
		max-width: 400px;
	}
}