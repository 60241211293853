.faq-list {
    .vsa-collapse-enter-active,
    .vsa-collapse-leave-active {
        transition-property: height, padding-top, padding-bottom;
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
    }

    .vsa-collapse-enter,
    .vsa-collapse-leave-active {
        opacity: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
    }

    .vsa-item__trigger { }

}
