
.share {
	text-align: center;
	position: relative;
	margin: 40px auto;
	
	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 1px;
		top: 50%;
		left: 0;
		background-color: $gray-lighter;
	}
	
	.icon-wrapper {
		display: inline-block;
		position: relative;
		z-index: 2;
		background-color: $white;
		padding: 0 20px;
		
		a {
			display: inline-block;
			color: $gray;
			margin: 0 8px;
			
			&:hover {
				color: $primary;
			}
			
			&:first-child {
				margin-right: 0;
			}
		}
	} 
}