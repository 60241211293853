
#nav-header, // nonno
.items  { // padre
	height: 42px;
	overflow-x: auto;
	overflow-y: hidden;
	text-align: center;
	box-sizing: border-box;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-x: scroll;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}

	* {
		box-sizing: border-box;
	}
}


#nav-header {
	border-top: 1px solid $gray-light;
	padding: 0;
}

.items {
	position: relative;
	width: 100%;
	white-space: nowrap;
	overflow-x: auto;
    overflow-y: hidden;
	transition: all 0.2s;
	user-select: none;
	cursor: pointer;
	padding-bottom: 50px;

	&.active {
		cursor: grabbing;
		cursor: -webkit-grabbing;
		opacity: 0.7;
	}
}


.item {
	display: inline-flex;
	border-right: 1px solid $gray-light;
	height: 42px;
	margin-left: -4px;

	&:first-child {
		border-left: 1px solid $gray-light;
	}

	a {
		display: block;
		position: relative;
		padding: 0 30px;
		color: $gray;
		font-size: 14px;
		line-height: 42px;
		text-decoration: none;
		text-transform: uppercase;

		&:hover,
		&.active,
		&.is-active {
			color: $primary;
		}

		&.submenu-parent {
		  padding-right: 40px;
		  background-image: url('/images/uikit/icons/chevron-down.svg');
		  background-repeat: no-repeat;
		  background-position: calc(100% - 20px) 50%;
		  background-size: 16px 16px;

		  &[class*="open-"] {
		  	background-image: url('/images/uikit/icons/chevron-right.svg');
		  }
		}
	}

	&.is-submenu {
		background-color: $primary;
		margin-bottom: -15px;
		overflow: hidden;
		width: 0;

  	    transition: all 0.15s ease-out;
		transform: scaleX(0);
		transform-origin: left;

  	&.open-servizi,
	&.open-profile {
  		//width: 320px; servizi
		//width: 284px; profile
		width: min-content;
		transform: scaleX(1);
  	}

		a {
			display: inline-flex;
			padding: 0 20px;
			color: $white;
			font-size: 12px;

			&:hover,
			&.active,
      		&.is-active{
				color: $white;
				text-decoration: underline;
			}
		}
	}
}
