@import './../../../node_modules/@creativebulma/bulma-collapsible';

.accordions {

    // single item
    .accordion {
        background-color: $white;
        font-size: $size-6;

        // header
        .accordion-header {
            color: $primary;
            background-color: $white;
            cursor: pointer;
            position: relative;
            padding-left: 20px;
            z-index: 1;

            .button {
                margin-left: 10px;
                float: right;
                display: none;
                height: auto;
                text-transform: uppercase;
            }

            span {
                display: inline-block;
                padding: 12px 0;
                line-height: 1;
            }

            + .accordion-body {
                border: 0;
                border-radius: 4px;
            }
        }

        &.is-active {

            .accordion-header {
                background-color: $primary;
                border-radius: 4px 4px 0 0;
                color: $white;

                overflow: hidden;
                padding: 0 0 0 20px;
                z-index: 2;

                .button {
                    display: flex;
                }

                span {
                    width: calc(100% - 140px);
                }
            }
        }



        // content
        .accordion-content {
            background-color: $gray-lighter;

            .columns {
                padding-bottom: 20px;
                margin: 0 auto;

                .column {

                    img {
                        display: block;
                        margin: 10px auto 16px;
                        max-width: 140px;
                    }
                }

                .column.is-4 {
                    position: relative;

                    img {
                        @include absolut-centered;
                        margin: 0;
                    }

                    + .column .description {
                        margin-left: 1em;
                    }
                }
            }
        }

        footer {
            border-top: 1px solid rgba(255,255,255,0.7);
            padding: 6px 10px;
            margin-bottom: -0.75rem;
            text-align: center;
            font-size: $size-7;

            img {
                position: relative;
                top: 2px;
                margin-right: 4px;
                width: 12px;
            }
        }
    }

    @include mobile {

        .accordion .accordion-content {

            .columns {
                width: 100% !important;
                float: none;
                display: block;

                .column.is-4 {
                    text-align: left;

                    img {
                        @include absolut-centered-reset;
                        margin: 10px 0 16px 0;
                    }

                    + .column .description {
                        margin-left: 0;
                    }
                }
            }

        }
    }
}
