// FAQ
//----------------------------

.faq-nav {
	border-left: 2px dotted $primary;
	padding-left: 30px;
	margin-bottom: 60px;

	a {
		color: $primary;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		background-color: $white;
		padding: 4px 0;
		line-height: 2;

		&:hover {
			border-bottom: 1px solid $gray;
		}

		&.active {
			margin-left: -33px;
			color: $gray-dark;

			&:hover {
				border-bottom: 1px solid transparent;
			}
		}
	}
}


.faq-item {
	padding: 4px 0 5px 60px !important;
	margin: 30px 0 0 -18px !important;
	background-image: url('/images/icon/faq.svg');
	background-repeat: no-repeat !important;
	background-position: 0 0 !important;;
	background-size: 35px 35px !important;;
    border: none !important;;

	.question, button {
		font-weight: 700;
		font-size: $size-5;
		margin-bottom: 1.25rem;
        outline: none !important;
        border: none !important;
        background-color: transparent;
        color: $primary;
        text-align: left;
        :active {
            outline: none !important;
            border: none !important;
        }
	}

	.answer {
		line-height: 1.7;
	}

	a {
		color: $primary;
	}
}

// Responsive
//----------------------------

@media only screen and (max-width: 639px) {

	.faq-nav {
		margin-left: 20px;
	}

	.faq-item {
		margin-left: 0;
		background-position: 2px 0;
	}
}
