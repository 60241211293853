
.footer {
	@include brand-fade-diagonal;
	padding-bottom: 0;

	* {
		color: $white;
	}

	.copyright {
		padding: 8px 3rem;
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		text-align: center;
		background-color: darken($secondary, 5%);
	}

	li {
		padding: 0.25rem 0;
	}

	p {
		margin-bottom: 0.75rem;
	}

	img {
		margin-right: 0.75rem;
	}

	a {
		opacity: 0.8;
		text-decoration: none;
		font-weight: 700;
		white-space: nowrap;

		&:hover {
			opacity: 1;
			color: $white;
		}
	}
	.providedby {
        width: 320px;
        max-width: 90%;
        margin: -1rem auto 2rem;
    } 
}
