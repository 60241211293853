
.input-gray {
	@extend input;
	border: 0;
	background-color: $gray-lighter;
}

.input-rounded {
	border-radius: 30px;
	padding: 0 20px;
	
	+ .is-rounded {
		float: right;
		position: relative;
		top: -36px;
		text-transform: uppercase;
	}
}


@media only screen and (max-width: 419px) {	

	/* input + button rounded */
	.input-rounded {
		
		+ .is-rounded {
			float: none;
			position: relative;
			top: 4px;
			width: 100%;
		}
	}
}