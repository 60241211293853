	/* BG */
	.bg-white {
		background-color: $white;
	}
	.bg-gray {
		background-color: $gray-dark;
	}
	.bg-turquoise {
		background-color: $secondary;
	}
	//&.bg-gray,
	//&.bg-turquoise {
	//
	//	*:not(footer) {
	//		color: $white;
	//	}
	//	.brand-secondary {
	//		color: $secondary;
	//	}
	//}
	.txt {
		max-width: 560px;
		margin: 0 auto;
	}
