#block-dropticket2019-local-tasks {

  ul.navbar {
    border: 1px solid #999;
    background: #eee;
  }
}

#edit-actions {
  .button {
    padding-bottom: calc(0.375em - 1px);
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: calc(0.375em - 1px);
    background: $info;
    color: #fff;
  }
}

.submenu-servizi {
  span {
    display: block;
    position: relative;
    padding: 0 30px;
    color: #5f7081;
    font-size: 14px;
    line-height: 42px;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.help-box {
  @extend .container;
  @extend .box;
    border: 1px solid $primary;
    box-sizing: unset !important;
    color: #666;
}

#node-page-layout-builder-form,
#layout-builder-discard-changes,
#layout-builder-revert-overrides{
  @extend .container;
  .form-wrapper {
    margin: 5px 0px 15px;
  }
  // buttons
  .button {
    font-weight: bolder;
  }
  #edit-submit {
    background-color: $primary !important;
  }
  #edit-revert {
    background-color: $danger !important;
  }
  #edit-discard-changes {
    background-color: $warning !important;
    color: #222;
  }
}

