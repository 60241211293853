@charset "utf-8";

@import url('//fonts.googleapis.com/css?family=Barlow:400,700,900&display=swap');
@import url('//fonts.googleapis.com/css?family=Zilla+Slab:400,400i,700&display=swap');

$fa-font-path: "../fonts/font-awesome";
@import "../../node_modules/font-awesome/scss/font-awesome";

@import '../../node_modules/bulma/sass/utilities/initial-variables';
@import '../../node_modules/bulma/sass/utilities/functions';

// colors
$gray-dark:					  hsl(210, 18%, 22%); //#2F3943;
$gray:						    hsl(211, 15%, 44%); //#607081;
$gray-light: 				  hsl(210, 9%, 70%); //#ABB2B9;
$gray-lighter: 				hsl(210, 17%, 88%); //#DCE1E6;

$primary: 					  hsl(170, 100%, 37%); //#00BD9C;
$droppay: 					        hsl(25,87%,51.6%); //#ef7018
$link:                $grey-dark;
$link-hover:          $primary;
$link-focus-border:   darken($primary, 5%);

$pagination-current-background-color:   $primary;
$pagination-current-border-color:       $primary;

$hr-background-color:                   $gray-light;
$hr-height:                             1px;

$background:                            $primary;

// typography
$family-sans-serif:             'Barlow', sans-serif;

// form
$input-shadow:                  none;

// Extensions
$divider-thickness:             0.01rem;


@import '../../node_modules/bulma';
@import "../../node_modules/bulma-helpers/bulma-helpers";
@import "../../node_modules/bulma-extensions/dist/css/bulma-extensions.min.css";

@import "utilities/_all";
@import "layout/_all";
@import "sections/_all";
@import "elements/_all";
@import "components/_all";
@import "form/_all";
@import "layout/map";

@import "home/_all";
@import "custom/_all";

@import "home/slideshow-tpl-logos";
@import 'components/vue-simple-accprdion';

@import "drupal/drupal";
