// Home Promotion
//----------------------------


.home-promotion {
	background-color: lighten($gray-lighter, 5%);

	.column {
		position: relative;
	}
	
	img {
		margin: 40px auto 0;
		position: relative;
		z-index: 2;
	}
	
	.discount {
		@include brand-fade-diagonal;

		position: absolute;
		z-index: 1;
		top: 10px;
		right: 40px;
		width: 80px;
		height: 50px;
		padding-top: 6px;
		
		border-radius: 3px 3px 0 0;
		color: $white;
		font-weight: 700;
		text-align: center;
	}

	@include mobile {
		
		.container {
			max-width: 480px;
		}
	}
}