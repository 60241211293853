
.button {
    padding: 12px 30px;

    &.is-large {
        padding: 0 2rem;

        @include mobile {

            &.is-responsive {
                padding: 0 1rem;
                font-size: $size-6;
                white-space: normal;
            }
        }
    }

    &.is-rounded {
        text-transform: uppercase;
        padding: 10px 30px;
    }

    &.is-droppay {
        background-color: $droppay;
        color: $white;
        border:0;
    }
}