// Home Slideshow servizi
//----------------------------

.slideshow {
    @include brand-fade-vertical;
    position: relative;
    padding-bottom: 20px;
    z-index: 2;

    p {
        color: $white;
    }

    .slick-list {
        padding: 0 !important;
    }


    .content {
        width: 1400px;
        margin: -10px auto 40px;
        padding: 40px 0;
        display: table !important;
        overflow: hidden;

        div {
            box-sizing: border-box;
        }

        > div {
            display: table-cell;
            vertical-align: middle;

        }

        .visual,
        .txt {
            width: 660px;
        }

        .empty {
            width: 80px;
        }

        .visual #anim_1 svg {
            width: 560px !important;
            height: 345px !important;
            margin-top: -30px;
        }

        .visual #anim_2 svg {
            width: 560px !important;
            height: 258px !important;
        }

        .visual #anim_3 svg {
            height: 340px !important;
        }
    }


    .slick-dots {
        text-align: center;
        height: 16px;
        bottom: 26px;

        li {
            position: relative;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: 0 8px;
            padding: 0;
            cursor: pointer;
        }

        li button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 16px;
            height: 16px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: $white;
            opacity: 0.70;
            border-radius: 10px;

            &::before {
               display: none;
            }
        }

        .slick-active button {
            opacity: 1;
            outline: none;
        }
    }
}


// Responsive
//----------------------------

@media only screen and (max-width: 1520px) {


    .slideshow {

        .content {
            width: 1200px;

            .visual,
            .txt {
                width: 560px;
            }
        }
    }
}

@media only screen and (max-width: 1260px) {

    .slideshow {

        .content {
            width: 1000px;

            .visual,
            .txt {
                width: 460px;
            }

            .visual #anim_1 svg {
                width: 460px !important;
                height: 283px !important;
            }

            .visual #anim_2 svg {
                width: 460px !important;
                height: 212px !important;
            }
        }
    }
}


@media only screen and (max-width: 1100px) {

    .slideshow {

        .content {
            width: 90%;

            .visual #anim_1 svg {
                width: 360px !important;
                height: 222px !important;
            }

            .visual #anim_2 svg {
                width: 360px !important;
                height: 166px !important;
            }
        }
    }
}

@media only screen and (max-width: 959px) {

    .slideshow {

        .content {
            text-align: center;

            .visual,
            .txt {
                max-width: 460px;
                display: block;
                margin: 0 auto;
            }

            .empty {
                display: none;
            }

            .visual {
                height: 200px;
            }

            .visual#anim_1 {
                margin-bottom: 22px;

                svg {
                    width: 300px !important;
                    height: 185px !important;
                }
            }

            .visual #anim_2 svg {
                width: 90% !important;
            }

            .visual #anim_3 {
                margin-bottom: 20px;

                svg {
                    height: 160px !important;
                }
            }
        }
    }
}


@media only screen and (max-width: 460px) {

    .slideshow {

        .content {

            .visual,
            .txt {
                width: auto;
                max-width: 100%;
            }

            .txt {
                max-width: 90vw;
                margin-left: 5vw;
                margin-right: 5vw;
            }

            .visual #anim_1 {

                svg {
                    width: auto !important;
                    height: 120px !important;
                }
            }
        }
    }
}
