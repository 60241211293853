
.page-header {
	text-align: center;
	position: relative;
	background-color: $white;
	z-index: 2;

	.top {
		padding: 1rem 1.5rem;
		position: relative;

		.button {
			@include absolut-centered-right;
			right: 1.5rem;
			padding: 6px 20px;
		}
	}

	.logo img {
		margin: 0 auto;
		width: 200px;
		// width: 240px; logo old
	}

	body & {
		background-color: transparent;
		border-bottom: 1px solid $gray-light;
	}
}

// Responsive
//----------------------------

@media only screen and (max-width: 767px) {

	.page-header {

		.top {
			padding: 18px 0 18px 24px;
			text-align: left;

			.button {
				right: 24px;
			}
		}

		.logo img {
			width: 180px;
		}
	}
}

@media only screen and (max-width: 440px) {

	.page-header {

		.top {
			padding-left: 16px;
			text-align: left;

			.button {
				right: 12px;
				padding: 4px 10px;
				font-size: 11px;
			}
		}

		.logo img {
			width: 162px;
		}
	}
}
