
.pager {
	margin: 30px 0 40px;
	text-align: center;
	
	span,
	a {
		color: $gray-dark;
		text-decoration: none;
		text-align: center !important;
		display: inline-block;
		margin: 0;
		
		&.has-text-gray-light {
			margin-right: 8px;
		}
	}
	
	a {
		width: 30px;
		height: 36px;
		line-height: 35px;
		padding: 0;
		
		&.active {
			color: $white;
			background-color: $primary;
			border-radius: 50%;
			cursor: default;
			width: 36px;
		}
	}
}

.paginate-links {
	text-align: center;

	li {
		display: inline-block;
		margin: 0 10px;

		a {
			display: block;
			font-weight: 700;
			background: $primary;
			color: $white;
			padding: 5px 14px 6px;
			border-radius: 30px;
			width: 110px;

			&:hover {
				background: darken($color:$primary, $amount: 5%);
			}
		}

		&.disabled a {
			opacity: 0.6;
			cursor: default;

			&:hover {
				background: $primary;
			}
		}
	}
}
