// Home
//----------------------------

@import 'hero.scss';
@import 'slideshow-services.scss';
@import 'promotion.scss';

@media only screen and (min-width: 1400px) {
    .slideshow .content {
        width: 1400px !important; // aggiungere l'important, sennò viene sovrascritto da altro
    }
}
