/* ========================================================================
   Img in Row Sections
 ========================================================================== */
 
.row-section {
	
	/* IMG */
	&.img-left,
	&.img-right { 
	
		img {
			position: absolute;
			max-height: 280px;
			max-width: 18vw;
		}
	}
	
	&.img-left img {
		right: calc(50% + 300px);
		bottom: 0;
	}
	&.img-right img {
		left: calc(50% + 300px);
		bottom: 0;
	}
}



// Responsive
//----------------------------



@media only screen and (max-width: 1023px) {
	
	.row-section.section-col {
		
		/* IMG */
		&.img-left,
		&.img-right { 
			padding-bottom: 230px;
		
			img {
				position: absolute;
				max-height: 200px !important;
				max-width: 80%;
				right: auto !important;
				left: 50% !important;
				transform:  translate(-50%, 0);
				
				&[style^="top"] {
					top: auto !important;
					bottom: -10px !important;
				}
			}
		}
	}
}


@media only screen and (max-width: 959px) {	

	.row-section {
		
		/* IMG */
		&.img-left,
		&.img-right { 
			padding-bottom: 230px;
		
			img {
				position: absolute;
				max-height: 200px !important;
				max-width: 80%;
				right: auto !important;
				left: 50% !important;
				transform:  translate(-50%, 0);
				
				&[style^="top"] {
					top: auto !important;
					bottom: -10px !important;
				}
			}
		}
	}
}

