
// SlideLogo

.parent- {

	.slider {

        img {
            width: auto;
            margin: auto;
            max-width: 120px;
            max-height: 80px;
            display: table-cell;
            vertical-align: middle;
        }

        .slick-slide div div {
            height: 100px;
            line-height: 100px;
            display: table;
            text-align: center;
        }
    }
}
