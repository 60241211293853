// Position

@mixin absolut-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

  @mixin absolut-centered-left {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
  }

  @mixin absolut-centered-right {
	position: absolute;
	top: 50%;
	left: auto;
	right: 0;
	transform: translate(0, -50%);
  }

  @mixin absolut-centered-bottom {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
  }

  @mixin absolut-centered-top {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
  }

  @mixin absolut-centered-reset {
	position: relative;
	top: auto;
	left: auto;
	transform: translate(0, 0);
  }



// Brand Gradient

@mixin brand-fade-diagonal {
	background: $secondary;
	background: -moz-linear-gradient(149deg, rgba(0,138,156,1) 0%, rgba(0,228,196,1) 100%);
	background: -webkit-linear-gradient(149deg, rgba(0,138,156,1) 0%, rgba(0,228,196,1) 100%);
	background: linear-gradient(149deg, rgba(0,138,156,1) 0%, rgba(0,228,196,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008a9c",endColorstr="#00e4c4",GradientType=1);
}

@mixin brand-fade-vertical {
	background: #00be9c;
	background: -moz-linear-gradient(0deg, rgba(0,228,196,1) 0%, rgba(0,190,156,1) 100%);
	background: -webkit-linear-gradient(0deg, rgba(0,228,196,1) 0%, rgba(0,190,156,1) 100%);
	background: linear-gradient(0deg, rgba(0,228,196,1) 0%, rgba(0,190,156,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00e4c4",endColorstr="#00be9c",GradientType=1);
}

@mixin grayscale_element($value) {
  -webkit-filter: #{"grayscale(#{$value})"};
  -moz-filter: #{"grayscale(#{$value})"};
  filter:#{"grayscale(#{$value})"};
}
