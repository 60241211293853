@import "badge.scss";

.download {
	background-color: $gray-dark;
	text-align: center;
	padding-top: 80px;
	padding-bottom: 80px;
	
	.txt {
		text-align: left;
		
		* {
			color: $white;
			text-transform: uppercase;
		}
		
		p {
			max-width: 600px;
		}
	}
	
	.visual {
		text-transform: uppercase;
		text-align: center;
		position: relative;
		
		p {
		  position: absolute;
		  margin: 0;
		  top: 0;
		  left: 0;
		  right: 0;
		  bottom: 0;
		  
		  transform-origin: 50% 50%;
		  transition: all .1s ease;
		}
		
		span {
			color: $white;
			font-weight: 700;
			font-size: 1.2rem;
			
			&:nth-child(-n+14) {
    		color: $color-sosta;   
			}
			
			&:nth-child(n+15) {
    		color: $color-trasporti;   
			}
			
			&:nth-child(n+47) {
    		color: $color-parchi;  
			}
			
			&:nth-child(n+71) {
    		color: $color-intrattenimento;  
			}
		}
	}
	
	div[renderer] {
		background-color: rgba(255,255,255,0.9);
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -130px 0 0 -130px;
		width: 260px;
		height: 260px;
		border-radius: 50%;
		overflow: hidden;
        transform: translate3d(0, 0, 0);

        text-align: center;
        box-sizing: border-box;
    
        &#lottie1 {
    	    padding-right: 40px;
    	    padding-bottom: 20px;
        }
    
        &#lottie3 {
			padding: 40px;
        }
	}
	
	
	&.download-small {
	
		.txt {
			text-align: center;
			
			> * {
				text-transform: none;
				margin: 0 auto;
			}
		}
	}
	
	
	&.download-light {
		background-color: $white;
	
		.txt {
			
			* {
				color: $gray-dark;
			}
		}
	}
}



// Responsive
//----------------------------


@media only screen and (max-width: 980px) {	

	.download {
		
		.txt  {
			
			p,
			.badge {
				margin: 0 auto;
			}
			
			.badge {
				margin-top: 20px;
				margin-bottom: 20px;
				
				a {
					margin-right: 4px;
					margin-left: 4px;
				}
			}
		}
	}
}


@media only screen and (max-width: 767px) {	

	.download {
		
		div[renderer] {
			display: none;
		}
		
		.txt  {
			padding-top: 0;
			padding-bottom: 0;
			text-align: center;

			.badge {
				margin-bottom: 0;
				
				a {
					display: block;
					margin: 10px auto 0;
				}
			}
		}
	}
}