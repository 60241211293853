

blockquote {
	font-family: $blockquote-family;
	padding-left: 50px;
	padding-top: 10px;

	background-image: url(/images/icon/quote.svg);
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 40px 30px;

	footer {
		margin-top: 0.45rem;
		color: $primary;
	}
}



.check-list {
	margin-left: 0 !important;

	li {
		list-style-type: none;
		padding: 3px 0 24px 44px;
		background-image: url(/images/icon/check-green.svg);
		background-position: 0 3px;
		background-repeat: no-repeat;
		background-size: 32px 32px;

		.hero & {
			background-image: url(/images/icon/check-white.svg);
		}

		.is-droppay & {
			background-image: url(/images/icon/check-orange.svg);
		}
	}
}



.number-round {
	text-align: center;
	display: inline-block;
	width: 36px;
	height: 36px;
	line-height: 35px;
	padding: 0;
	color: $white;
	background-color: $primary;
	border-radius: $radius-rounded;  //50%;


    &.is-droppay {
        background-color: $droppay;
    }
}
