
.home-hero {
    position: relative;
    margin-top: -50px;
    margin-bottom: -8px;
    z-index: 1;

    .call {
        position: absolute;
        top: 20vh;
        left: 12vw;
        z-index: 2;

        * {
            text-transform: uppercase;
        }

        h1 {
            font-weight: 900;

            span {
                display: block;
                font-weight: normal;
            }
        }

        .button {
            padding: 14px 40px;
            border-bottom: 4px solid darken($primary, 5%);

            &:hover {
                padding-top: 16px;
                padding-bottom: 12px;
            }
        }
    }
}

.header-ie {
    display: none;
}


/*
 * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
 *
 * [1] IE9
 * [2] IE10+
 */
/* 1 */
.ie9 .home-hero > svg {
    width: 100% !important;
    height: auto !important;
}

/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

    .home-hero .header-ie {
        display: block;
        width: 100%;
    }

    .home-hero > svg {
        display: none;
    }
}


// Responsive
//----------------------------

@media only screen and (max-width: 1520px) {

    .home-hero {

        .call {
            top: 16vh;
            left: 9vw;

            .button {
                padding: 10px 30px;

                &:hover {
                    padding-top: 12px;
                    padding-bottom: 8px;
                }
            }
        }
    }
}


@media only screen and (max-width: 1100px) {

    .home-hero {

        .call {
            top: 13vh;
            left: 7vw;

            .button {
                padding: 10px 30px;

                &:hover {
                    padding-top: 12px;
                    padding-bottom: 8px;
                }
            }
        }
    }
}


@media only screen and (max-width: 959px) {


    .home-hero {
        margin-top: auto;

        .call {
            position: relative;
            top: 30px;
            left: auto;
            margin: 0 auto;
            width: 96%;
            text-align: center;

            h1 {
                padding-left: 20px;
                padding-right: 20px;

                span {
                    display: inline-block;
                }
            }

            .button {
                padding: 10px 30px;

                &:hover {
                    padding-top: 12px;
                    padding-bottom: 8px;
                }
            }
        }
    }
}

@media only screen and (max-width: 560px) {

    .home-hero {
        margin-top: 30px;

        .call {
            position: relative;
            top: -10px;
            left: auto;
            margin: 0 auto;
            width: 96%;
            text-align: center;

            .button {
                padding: 6px 20px;

                &:hover {
                    padding-top: 8px;
                    padding-bottom: 4px;
                }
            }
        }
    }
}

@media only screen and (max-width: 460px) {

    .home-hero {

        .call {

            .button {
                padding: 6px 20px 4px;

                &:hover {
                    padding-top: 8px;
                    padding-bottom: 2px;
                }
            }
        }
    }
}
