
.hero {
  @include brand-fade-diagonal;
	padding: 4px 0;
	margin: 0 auto;
	box-sizing: border-box;
  	z-index: 2;

  .content {
    margin: -4px auto;
		width: 70vw;
		position: relative;
		
		background-repeat: no-repeat;
		background-position: 100% 100%;
    	background-size: auto calc(100% - 30px);
	

    .txt { /* solo testo */
      margin: 100px auto;
      width: 60vw;
      max-width: 90%;
      text-align: center;
      
      * {
        color: #fff;
      }
    }
    
    &[style*="url"] { /* testo + immagine */
			min-height: 460px;
			
			.txt {
				@include absolut-centered-left;
				margin: 0;
				width: 50%;
				text-align: left;
			}
		}
  }
}

// Responsive
//----------------------------


@media only screen and (max-width: 1600px) {	

	.hero {
	
		.content {
			width: 90vw;
		}
	}
}



@media only screen and (max-width: 1400px) {	

	.hero {
	
		.content {
			
			.txt {
				margin: 80px auto;
			}
		}
	}
}



@media only screen and (max-width: 1300px) {	

	.hero {
	
		.content {
			width: 94vw;
		}
	}
}


@media only screen and (max-width: 1200px) {	

	.hero {
	
		.content {
			min-height: 0;
			padding: 40px 0;
			background-size: auto 180px;
			background-position: 50% 100%;
			
			&[style*="url"] {
				min-height: 0;
				padding-bottom: 200px;
		
				.txt { 
				  @include absolut-centered-reset;
					width: 100%;
					margin: 0 auto;
					text-align: center;
				}

				.check-list li {
					background-image: none;
					padding: 3px 0 14px 0px;
					margin-left: 20px;
					list-style-type: inherit;
				}
			}
		
			div {
				width: 100%;
				margin: 0 auto;
			}
		}
	}
}