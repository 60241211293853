// Pagina dettaglio
//----------------------------

.article-content {
	
	.image-wrapper {
		overflow: hidden;
		margin-bottom: 30px;

		img {
			border-radius: 3px;
		}
	}
	
	p:not([class]) {
		font-size: $size-5;
	}
}


.article-intro {
	margin: 0 auto 40px;
		
	p {
		margin-top: 20px;
		font-weight: 700;
	}
	
	.divider {
		width: 200px;
		height: 6px;
		background-color: $primary;
	}
}