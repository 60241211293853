
// Colors
$secondary:							#00BAB1;
$droppay: 					        hsl(25,87%,51.6%); //#ef7018;

$color-sosta:					    #2785e5;
$color-trasporti:					#f2a832;
$color-parchi:						#03a9f4;
$color-intrattenimento:				#e91e63;

// Typography
$blockquote-family:			        'Zilla Slab', serif;
$blockquote-font-size:              $small-font-size;
$blockquote-font-style:             italic;
$blockquote-footer-font-size:       0.875rem; // 14px


